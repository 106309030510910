<template>
  <aside class="ui-product__info">
    <div class="ui-switch">
      Pronto per la vendita
      <CSwitch
        variant="3d"
        size="sm"
        color="secondary"
        :checked.sync="product.is_active"
      />
    </div>

    <CCard class="card-bordered" v-if="showPreviewButton">
      <CCardBody>
        <CButton
          color="primary"
          size="sm"
          @click.prevent="showPreview = true"
          block
          >VEDI ANTEPRIMA</CButton
        >
      </CCardBody>
      <ProductFormPreviewModal
        v-if="showPreview"
        :show.sync="showPreview"
        :title="`Anteprima`"
        :product="product"
        :variants="variants"
        :lang="lang"
      />
    </CCard>

    <CCard class="card-bordered" v-if="showVariants && variants.length > 0">
      <CCardBody>
        <section class="mb-4" v-for="variant in variants" :key="variant.id">
          <div class="row justify-content-between">
            <div class="col-auto ui-type-heading">{{ variant.name }}</div>
            <div class="col-auto">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="variant.is_active"
              />
            </div>
          </div>

          <!-- <div>Mercato...</div> -->
          <div class="row justify-content-between">
            <!-- <div class="col-auto">
              <CBadge shape="pill" color="success">in vendita</CBadge>
            </div> -->
            <div class="col-auto">
              <strong>{{ formatPrice(variant.price) }}</strong> CHF / pezzo
            </div>
          </div>
          <!-- <hr class="my-2" />
          <div>La bottega del maestro Piff</div>
          <div class="row justify-content-between">
            <div class="col-auto">
              <CBadge shape="pill" color="info">in approvazione</CBadge>
            </div>
          </div>
          <hr class="my-2" />
          <CButton color="primary" size="sm">VEDI ANTEPRIMA</CButton> -->
        </section>
      </CCardBody>
    </CCard>
  </aside>
</template>

<script>
import currency from "currency.js";
import ProductFormPreviewModal from "./ProductFormPreviewModal";
export default {
  name: "ProductFormInfo",
  components: {
    ProductFormPreviewModal,
  },
  props: {
    product: { type: Object, required: true },
    variants: { type: Array, default: () => [] },
    lang: { type: String, required: true },
    showVariants: { type: Boolean, default: false },
    showPreviewButton: { type: Boolean, default: false },
  },
  data() {
    return {
      showPreview: false,
    };
  },
  methods: {
    formatPrice(price) {
      const cur = (value) => currency(value, { symbol: "€", precision: 2 });
      return cur(price);
    },
  },
};
</script>
