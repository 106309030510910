import { getOptionLabel, getTranslation } from "../helpers/common";
import { getLastMinTOptions, productDietOptions } from "../helpers/options";
import get from "lodash/get";

const productMixin = {
  methods: {
    productName(product, lang) {
      return getTranslation(product.translations, lang);
    },

    productDescription(product, lang) {
      return getTranslation(product.translations, lang, "description");
    },

    productHasDietInfo(product) {
      const diet = get(product, "extra.diet", {});
      return Object.values(diet).includes(true);
    },

    productDietInfo(product) {
      const diet = get(product, "extra.diet", {});
      const options = productDietOptions();
      const selected = Object.keys(diet).filter((k) => diet[k]);
      return selected
        .map((diet) => {
          return getOptionLabel(diet, options);
        })
        .join(", ");
    },

    productHasManyVariants(variants) {
      return variants.length > 1;
    },

    productHasOneVariant(variants) {
      return variants.length === 1;
    },

    pruductVariatName(variant, lang) {
      return variant ? getTranslation(variant.translations, lang) : "--";
    },

    productVariantHasGuaranteedDuration(variant) {
      return variant && variant.last_min_n && variant.last_min_t;
    },

    productVariantGuaranteedDuration(variant) {
      if (!variant) {
        return "--";
      }
      const options = getLastMinTOptions();
      const { last_min_n, last_min_t } = variant;
      return `${last_min_n} ${getOptionLabel(last_min_t, options)}`;
    },

    productHasCertifications(product) {
      return product.certifications.length > 0;
    },

    needPriceCorrection(product, variant) {
      return product.pw || (variant && variant.pw === true);
    },
  },
};

export default productMixin;
